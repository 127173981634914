import {$currentPage, $formClickedButton, $recordedVideo, $userData} from "./stores";
import {
	changeUserDataEv, finishHREventEv, formIsValidEv, saveFormDataEv, saveToS3Ev,
	sendRegistrationDataEv, sendUserDataEv,
	setFormClickedButtonEv,
	setNextStepEv, setRecordedVideoEv
} from "./events";
import {sample} from "effector";
import {saveToS3Fx, sendHREventFx, sendUserDataFx} from "./effects";
import {answers_dictionary, categories, reverse_answers} from "../../pages/HrEvent/questions_dictionary";
import {calculateValues} from "../../utils/test-utils";
import AWS from "aws-sdk";

$currentPage.on(setNextStepEv, (state, payload) => {
	if(Number.isInteger(payload)) return payload
	return state + 1
})

$formClickedButton.on(setFormClickedButtonEv, (state, payload) => payload)

sample({
	source: $userData,
	clock: changeUserDataEv,
	fn: (source, clock) => {
		if(clock.field === 'tags'){
			if(source[clock.field].includes(clock.value)){
				return {
					...source,
					[clock.field]: source[clock.field].filter(item => item !== clock.value)
				}
			} else {
				return {
					...source,
					[clock.field]: [...source[clock.field], clock.value]
				}
			}
		}
		return {
			...source,
			[clock.field]: clock.value
		}
	},
	target: $userData
})

sample({
	source: $formClickedButton,
	clock: formIsValidEv,
	filter: (source, clock) => source === 2,
	target: setNextStepEv
})

sample({
	source: $formClickedButton,
	clock: formIsValidEv,
	filter: (source, clock) => source === 1,
	target: sendRegistrationDataEv
})

sample({
	source: $userData,
	clock: saveFormDataEv,
	fn: (source, clock) => {
		return {
			...source,
			...clock
		}
	},
	target: $userData
})

sample({
	source: $userData,
	clock: finishHREventEv,
	fn: (source, clock) => {
		return {
			place_id: 'kptz13x06',
			metadata: {
				user_name: source.name,
				user_surname: source.surname,
				user_occupation: source.work
			},
			data_url: [source.images],
			user_test_result: calculateValues(
				clock,
				categories,
				reverse_answers, Object.keys(answers_dictionary).length + 1
			)
		}
	},
	target: sendHREventFx
})

sample({
	clock: sendHREventFx.doneData,
	fn: () => 4,
	target: setNextStepEv
})

sample({
	source: $userData,
	clock: saveToS3Ev,
	fn: (tags, file) => {
		return {
			tags,
			file
		}
	},
	target: saveToS3Fx
})

sample({
	clock: saveToS3Fx.doneData,
	fn: () => 4,
	target: setNextStepEv
})

sample({
	source: $userData,
	clock: sendUserDataEv,
	fn: (source) => {
		return {
			place_id: 'test77a7a7',
			user_name: source.name,
			user_surname: source.surname,
			data_url: [source.images],
			properties: {
				bla1: 'bla1',
				bla2: 'bla2',
				bla3: 'bla3',
				user_work: source.work,
				is_agreement: source.isAgreement
			}
		}
	},
	target: sendUserDataFx
})

sample({
	clock: sendUserDataFx.doneData,
	fn: () => 4,
	target: setNextStepEv
})
